/*global*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}
:root {
  --primaryColor: #a1d8ee;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #cfdde2;
  --darkGrey: #2c2f2f;
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}
body {
  margin-top: 6rem;
}
/*navbar*/
/*while screen is less than 768px*/
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--mainWhite);
}
.navname {
  text-decoration: none;
  color: var(--mainBlack);
  font-family: "Sacramento", cursive;
  font-size: 2rem;
}
.nav-header {
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 1.7rem;
  right: 3rem;
}

.nav-icon {
  font-size: 1.5rem;
  color: var(--primaryColor);
}
.nav-links {
  height: 0;
  overflow: hidden;
  list-style-type: none;
}
.nav-links a {
  display: block;
  text-decoration: none;
  color: var(--mainBlack);
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.3rem;
}
.nav-links a:hover {
  color: var(--primaryColor);
}
.show-nav {
  height: 140px;
}
/*navbar when screen is greater than 768px*/

@media screen and (min-width: 768px) {
  .nav-btn {
    display: none;
  }
  .nav-center {
    display: grid;
    grid-template-columns: 3fr auto;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .nav-links {
    height: 50%;
    margin: auto;
    display: flex;
    text-align: center;
    padding-right: 2rem;
    padding-top: 0.5rem;
    color: var(--mainBlack);
  }
  .nav-links a {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: var(--mainBlack);
  }
}

/*art page css while screen full size*/
.art-list {
  border: 2px solid var(--mainGrey);
  margin-top: 5rem;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-bottom: 5rem;
}
.art-list-1 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}
.art-list p {
  margin-bottom: 2rem;
  text-align: center;
}
.art-img-pic {
  padding: 1rem;
  max-width: 15rem;
}
/*art page while screen less than 768*/
@media screen and (max-width: 768px) {
  .art-list {
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .art-list-1 {
    margin-top: 1rem;
  }
}

/*home/about section when screen small*/
.container {
  border: 2px solid var(--mainGrey);
  margin-top: 4rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 4rem;
  color: var(--darkGrey);
}

.info-container {
  margin: 2rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  line-height: 1.2rem;
}
.about-img-container img {
  max-width: 15rem;
  margin-bottom: 1rem;
}
.about-list-1 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
}
.about-img-pic {
  margin-top: 2rem;
  margin-right: 2rem;
  margin-left: 2rem;
  margin-bottom: 0.5rem;
  max-height: 17rem;
  object-fit: contain;
}

.about-p {
  padding-bottom: 1rem;
  font-size: 0.8rem;
}
.about-text p {
  padding-top: 1rem;
}
.about-text h3 {
  padding-top: 0.5rem;
}

.renderedList:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 50%;
  border-bottom: 1px solid var(--mainGrey);
}
.about-h3 {
  padding-bottom: 0.5rem;
  font-size: 1rem;
}
/*home about when screen large*/
@media screen and (min-width: 768px) {
  .container {
    border: 2px solid var(--mainGrey);
    margin-top: 5rem;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-bottom: 5rem;
    color: var(--darkGrey);
  }
  .info-container {
    display: grid;
    grid-template-columns: 1fr auto;
    text-align: left;
    margin-top: 3rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 3rem;
  }

  .about-info1 img {
    justify-content: center;
    max-height: 17rem;
  }
  .about-text {
    padding-left: 1.5rem;
  }
  .about-p {
    padding-bottom: 1rem;
  }

  .about-list-1 {
    padding-bottom: 2rem;
    justify-content: center;
  }
  .renderedList:before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 50%;
    border-bottom: 1px solid var(--mainGrey);
  }
}

/*coding page*/

.codingpic {
  max-width: 15rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.coding-container {
  border: 2px solid var(--mainGrey);
  margin-top: 5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 5rem;
}
.coding-div {
  display: grid;
  text-align: center;
  margin-top: 2rem;
  color: var(--darkGrey);
  margin-bottom: 2rem;
}
@media screen and (min-width: 768px) {
  .coding-container {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .codingpic {
    max-width: 20rem;
  }
}
/*footer*/
.footer {
  text-align: center;
  padding-bottom: 1em;
  padding-top: 1rem;
  color: var(--mainWhite);
  background-color: var(--primaryColor);
}

/*travel*/
.travel-comp {
  border: 2px solid var(--mainGrey);
  color: var(--darkGrey);
  margin-top: 5rem;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-bottom: 5rem;
}
.cityList {
  margin: 3rem;
  margin-bottom: 2rem;
}
.allcities {
  display: grid;
  grid-template-columns: 1fr auto;
  padding-bottom: 2rem;
}
.travel-img-pic {
  max-width: 15rem;
}
.travel-text-container {
  margin-left: 1.5rem;
}
.travel-text-container h1 {
  padding-bottom: 1rem;
}
.travel-text-container h4 {
  padding-bottom: 0.7rem;
}

@media screen and (max-width: 768px) {
  .travel-comp {
    border: 2px solid var(--mainGrey);
    color: var(--darkGrey);
    margin-top: 5rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 5rem;
  }
  .allcities {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
  .cityList {
    margin: 2rem;
  }
  .travel-text-container {
    margin-left: 0;
  }
  .travel-text-container h1 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
  }
  .travel-text-container h4 {
    text-align: center;
  }
  .travel-text-container p {
    text-align: center;
  }
}
